.styled-form {
  @apply text-base;

  input,
  textarea {
    @apply block w-full appearance-none border border-gray-light bg-white px-5 py-4 text-black placeholder-gray;

    &.has-error {
      @apply border-error;
    }

    &:focus {
      @apply border-gray;
    }
  }

  select.js-dropdown {
    @apply h-[58px];
  }

  .js-dropdown {
    .edd-root {
      @apply border border-gray-light;

      &:not(.edd-root-has-value) {
        @apply text-gray;
      }
    }

    .edd-head {
      @apply py-4 pl-5;
    }

    &.has-error .edd-root {
      @apply border-error;
    }
  }

  .field.text,
  .field.textarea,
  .field.dropdown {
    .form-field > label,
    .form-field-label {
      @apply sr-only;
    }
  }

  .field.optionset {
    & > label,
    .form-field > label {
      @apply font-medium;
    }
  }

  .form-field-description {
    @apply mt-2 text-base italic;
  }

  .field ul.optionset {
    @apply mt-1;
  }

  .field.checkboxset {
    li {
      @apply my-1;
    }
  }

  .field.optionset .form-field > ul {
    @apply md:flex md:space-x-4;
  }

  .field.checkboxset li,
  .field.checkbox {
    label {
      @apply relative inline-flex cursor-pointer items-baseline text-blue transition-colors hover:text-green;

      &:before {
        @apply relative top-[0.25em] mr-3 h-5 w-5 flex-shrink-0 rounded border border-gray-light bg-white xl:top-[0.4em] xl:h-6 xl:w-6;
        content: '';
      }
    }

    input[type='checkbox'] {
      @apply invisible absolute w-0;

      &:checked ~ label {
        &:before {
          @apply border-green bg-center bg-no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.581.429A1.454 1.454 0 0 0 10.995.11a1.44 1.44 0 0 0-.471.318L4.438 6.486l-1.95-1.964A1.456 1.456 0 1 0 .418 6.571l2.978 3a1.454 1.454 0 0 0 2.057.004l7.122-7.084a1.467 1.467 0 0 0 .01-2.055l-.003-.007Z' fill='%23017971'/%3E%3C/svg%3E");
          background-size: 60%;
        }
      }
    }

    &.has-error {
      .form-field-message.bad {
        @apply ml-4;
        padding-left: 14px;
      }
    }
  }

  .field.optionset:not(.checkboxset),
  .field.userformsoptionset {
    .optionset,
    .middleColumn {
      li,
      .odd,
      .even {
        &:not(:first-child) {
          @apply mt-2;
        }

        label {
          @apply relative inline-flex cursor-pointer items-baseline text-blue transition-colors hover:text-green;

          &:before {
            @apply relative top-[0.25em] mr-3 h-5 w-5 flex-shrink-0 rounded-full border border-gray-light bg-white xl:top-[0.4em] xl:h-6 xl:w-6;
            content: '';
          }
        }

        input[type='radio'] {
          @apply invisible absolute w-0;

          &:checked ~ label {
            &:before {
              @apply border-[7px] border-green bg-white xl:border-[8px];
            }
          }
        }
      }
    }
  }

  .field.ebookcheckboxset,
  .field.brochurecheckboxset {
    ul {
      @apply -mt-8 grid gap-6 md:-mt-12 md:grid-cols-2 xl:-mt-16 xl:gap-8;
    }

    li {
      label {
        @apply relative flex h-full w-full cursor-pointer flex-col xl:flex-row xl:items-center;

        span {
          @apply mb-3 flex h-full items-center border-b border-gray-light pb-3 xl:m-0 xl:w-4/5 xl:border-r xl:border-b-0 xl:pb-0 xl:pr-4 2xl:pr-8;
        }

        &:before {
          @apply relative order-2 h-5 w-5 flex-shrink-0 rounded border border-blue bg-white xl:absolute xl:right-[4.5%] xl:top-1/2 xl:-mt-6 xl:h-6 xl:w-6;
          content: '';
        }

        &:after {
          @apply absolute bottom-0 ml-8 text-sm font-medium xl:right-[2%] xl:bottom-auto xl:top-1/2 xl:ml-0 xl:mt-1 xl:text-base;
          content: 'Select';
        }
      }

      input[type='checkbox'] {
        @apply invisible absolute w-0;

        &:checked ~ label {
          &:before {
            @apply border-green bg-center bg-no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.581.429A1.454 1.454 0 0 0 10.995.11a1.44 1.44 0 0 0-.471.318L4.438 6.486l-1.95-1.964A1.456 1.456 0 1 0 .418 6.571l2.978 3a1.454 1.454 0 0 0 2.057.004l7.122-7.084a1.467 1.467 0 0 0 .01-2.055l-.003-.007Z' fill='%23017971'/%3E%3C/svg%3E");
            background-size: 60%;
          }
        }
      }
    }

    &.has-error {
      .form-field-message.bad {
        @apply mt-6 block text-center text-lg;
      }
    }
  }

  .field.file {
    position: relative;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }

    &.has-error {
      .form-field-message.bad {
        @apply w-full;
      }
    }

    .form-field {
      @apply md:inline-flex;

      label {
        @apply md:min-w-[120px];
      }
    }

    .file-input-placeholder {
      @apply cursor-pointer font-medium text-green transition-colors hover:text-blue;
    }
  }

  .field.date {
    .form-field {
      @apply relative;
    }

    svg {
      @apply absolute bottom-0 left-0 mb-4 w-4 lg:bottom-0.5;
    }

    input {
      @apply pl-7;
    }
  }

  .form-actions [type='submit'] {
    &:disabled {
      @apply cursor-not-allowed opacity-25;
    }
  }

  &.userform {
    .form-step {
      @apply grid gap-4 md:gap-6;
    }

    .field {
      label,
      legend {
        @apply mb-1 block text-base font-medium;

        sup {
          @apply -top-0.5 text-sm;
        }
      }
      .checkbox {
        .error.message {
          @apply block;
        }
      }
    }

    .requiredField {
      &.error {
        @apply border border-error;
      }
    }

    .error {
      &.message {
        @apply mt-2 inline-block text-xs font-normal text-error lg:text-sm;
      }
    }

    .Actions {
      @apply mx-auto mt-8 text-center md:mt-10;

      .action {
        @apply inline-flex cursor-pointer justify-center border border-green bg-green py-3 px-5 text-center font-medium text-white transition-colors hover:border-blue hover:bg-blue md:px-6;
      }
    }

    #uff {
      @apply text-center font-medium xl:text-lg;
    }
  }
}

.custom-form {
  .field-grid {
    @apply grid gap-4 md:grid-cols-2 md:gap-6;

    .col-width-half {
      @apply md:col-span-1;
    }

    .col-width-full {
      @apply md:col-span-2;
    }

    .composite-field-grid {
      @apply grid gap-4 md:gap-6;
    }
  }
}

.form-messages {
  @apply xl:text-lg;
}

.form-messages-center .form-messages {
  @apply text-center;
}

.form-field-message,
.message.required {
  @apply mt-2 inline-block text-xs font-normal text-error lg:text-sm;

  .field.checkbox & {
    @apply block;
  }

  .field.optionset & {
    @apply px-0 md:block;
  }
}

.ts-wrapper.single .ts-control,
.ts-wrapper.single .ts-control input {
  cursor: pointer;
}

.ts-wrapper.plugin-drag_drop.multi > .ts-control > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.plugin-checkbox_options .option input {
  margin-right: 0.5rem;
}

.plugin-clear_button .ts-control {
  padding-right: calc(1em + (3 * 6px)) !important;
}
.plugin-clear_button .clear-button {
  opacity: 0;
  position: absolute;
  top: 8px;
  right: calc(8px - 6px);
  margin-right: 0 !important;
  background: transparent !important;
  transition: opacity 0.5s;
  cursor: pointer;
}
.plugin-clear_button.single .clear-button {
  right: calc(8px - 6px + 2rem);
}
.plugin-clear_button.focus.has-items .clear-button,
.plugin-clear_button:not(.disabled):hover.has-items .clear-button {
  opacity: 1;
}

.ts-wrapper .dropdown-header {
  position: relative;
  padding: 10px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.ts-wrapper .dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
}
.ts-wrapper .dropdown-header-close:hover {
  color: black;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  box-shadow: none;
  border: 1px solid #d0d0d0;
}
.plugin-dropdown_input .dropdown-input {
  border: 1px solid #d0d0d0;
  border-width: 0 0 1px 0;
  display: block;
  padding: 8px 8px;
  box-shadow: none;
  width: 100%;
  background: transparent;
}
.plugin-dropdown_input .items-placeholder {
  border: 0 none !important;
  box-shadow: none !important;
  width: 100%;
}
.plugin-dropdown_input.has-items .items-placeholder,
.plugin-dropdown_input.dropdown-active .items-placeholder {
  display: none !important;
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
  min-width: 0;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
  flex: none;
  min-width: 4px;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-webkit-input-placeholder {
  color: transparent;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-ms-input-placeholder {
  color: transparent;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
  color: transparent;
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
  display: flex;
}
.ts-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.ts-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}

.ts-wrapper.plugin-remove_button .item {
  display: inline-flex;
  align-items: center;
  padding-right: 0 !important;
}
.ts-wrapper.plugin-remove_button .item .remove {
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 0 6px;
  border-left: 1px solid #d0d0d0;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  margin-left: 6px;
}
.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.ts-wrapper.plugin-remove_button .item.active .remove {
  border-left-color: #cacaca;
}
.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
  background: none;
}
.ts-wrapper.plugin-remove_button.disabled .item .remove {
  border-left-color: white;
}
.ts-wrapper.plugin-remove_button .remove-single {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 23px;
}

.ts-wrapper {
  position: relative;
}

.ts-dropdown,
.ts-control,
.ts-control input {
}

.ts-control,
.ts-wrapper.single.input-active .ts-control {
  background: #fff;
  cursor: text;
}

.ts-control {
  @apply border border-gray-light px-5 py-4 text-gray;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
}
.ts-wrapper.multi.has-items .ts-control {
  padding: calc(8px - 2px - 0) 8px calc(8px - 2px - 3px - 0);
}
.full .ts-control {
  background-color: #fff;
}
.disabled .ts-control,
.disabled .ts-control * {
  cursor: default !important;
}
.focus .ts-control {
  box-shadow: none;
}
.ts-control > * {
  vertical-align: baseline;
  display: inline-block;
}
.ts-wrapper.multi .ts-control > div {
  @apply text-sm;
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #f2f2f2;
  color: #303030;
  border: 0 solid #d0d0d0;
}
.ts-wrapper.multi .ts-control > div.active {
  background: #e8e8e8;
  color: #303030;
  border: 0 solid #cacaca;
}
.ts-wrapper.multi.disabled .ts-control > div,
.ts-wrapper.multi.disabled .ts-control > div.active {
  color: #7d7d7d;
  background: white;
  border: 0 solid white;
}
.ts-control > input {
  flex: 1 1 auto;
  min-width: 7rem;
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
  box-shadow: none !important;
}
.ts-control > input::-ms-clear {
  display: none;
}
.ts-control > input:focus {
  outline: none !important;
}
.has-items .ts-control > input {
  margin: 0 4px !important;
}
.ts-control.rtl {
  text-align: right;
}
.ts-control.rtl.single .ts-control:after {
  left: 15px;
  right: auto;
}
.ts-control.rtl .ts-control > input {
  margin: 0 4px 0 -2px !important;
}
.disabled .ts-control {
  opacity: 0.5;
  background-color: #fafafa;
}
.input-hidden .ts-control > input {
  opacity: 0;
  position: absolute;
  left: -10000px;
}

.ts-dropdown {
  @apply border border-gray-light;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #fff;
  border-top: 0 none;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.ts-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.ts-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}
.ts-dropdown .option,
.ts-dropdown .optgroup-header,
.ts-dropdown .no-results,
.ts-dropdown .create {
  padding: 5px 8px;
}
.ts-dropdown .option,
.ts-dropdown [data-disabled],
.ts-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}
.ts-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}
.ts-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.ts-dropdown .optgroup-header {
  color: #303030;
  background: #fff;
  cursor: default;
}
.ts-dropdown .create:hover,
.ts-dropdown .option:hover,
.ts-dropdown .active {
  background-color: #f5fafd;
  color: #495c68;
}
.ts-dropdown .create:hover.create,
.ts-dropdown .option:hover.create,
.ts-dropdown .active.create {
  color: #495c68;
}
.ts-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}
.ts-dropdown .spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px 8px;
}
.ts-dropdown .spinner:after {
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 50%;
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 transparent #d0d0d0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ts-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.ts-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.field.listbox.has-error .form-field .ts-control {
  @apply border-error;
}
